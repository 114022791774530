import { useEffect } from "react";
import { VideoTexture } from "three";
import hostVideo from "/videos/NBHostVideo.mp4";

type Props = {
  setHasAcceptedPermissions: (value: boolean) => void;
  setHasPlayedHostVideo: (value: boolean) => void;
  setLoadedVideoTexture: (texture: THREE.VideoTexture) => void;
  setLoadedVideo: (video: HTMLVideoElement) => void;
};

export default function PermissionOverlay({
  setHasAcceptedPermissions,
  setHasPlayedHostVideo,
  setLoadedVideo,
  setLoadedVideoTexture,
}: Props) {
  const requestDevicePermission = () => {
    if (
      typeof (DeviceOrientationEvent as any).requestPermission === "function"
    ) {
      (DeviceOrientationEvent as any)
        .requestPermission()
        .then((state: string) => {
          if (state === "granted") {
            console.log("Permissions granted");
            setHasAcceptedPermissions(true);
          }
        })
        .catch(console.error);
    } else {
      console.log("No permissions needed");
      setHasAcceptedPermissions(true);
    }
  };

  const loadVideo = () => {
    const vid = document.createElement("video");
    vid.src = hostVideo;
    vid.loop = false;
    vid.muted = false;
    vid.autoplay = false;
    vid.playsInline = true;

    vid.onended = () => {
      setHasPlayedHostVideo(true);
    };

    vid.load();

    const texture = new VideoTexture(vid);
    texture.needsUpdate = true;
    texture.colorSpace = "srgb";

    setLoadedVideo(vid);
    setLoadedVideoTexture(texture);
    requestDevicePermission();

    return vid;
  };

  return (
    <div
      style={{
        position: "absolute",
        width: "100vw",
        height: "100dvh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#f4f5f8",
        zIndex: "999",
      }}
    >
      <div
        style={{
          backgroundImage: 'url("/images/permissions_background.webp")',
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          padding: "30px",
          // borderRadius: "10px",
          marginInline: "40px",
          position: "fixed",
          bottom: "0",
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            position: "absolute",
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "40px",
            fontWeight: "bold",
            fontFamily: "Noto Sans",
            // top: "61.5%",
            bottom: "2vh",
          }}
        >
          <img
            style={{
              width: "30px",
            }}
            src="/images/NBSoundUp.gif"
          />
          <h2 className="text-lg text-red-600">Turn Your Volume Up</h2>
        </div>
        <div
          style={{
            position: "absolute",
            // top: "85%",
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
            color: "white",
            padding: "15px",
            borderRadius: "5px",
            cursor: "pointer",
            // fontWeight: "bold",
            textAlign: "center",
            fontSize: "38px",
            fontFamily: "Noto Sans",
            bottom: "6vh",
          }}
          onClick={loadVideo}
        >
          Tap to Enter
        </div>
      </div>
    </div>
  );
}
