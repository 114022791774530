import {
  onAuthStateChanged,
  signInAnonymously,
  User,
  UserCredential,
} from "firebase/auth";
import { createContext, useContext, useEffect, useState } from "react";
import { auth } from "../firebase/firebase";
import UserAccountService from "@/services/user-account.service";

export default function useFirebaseAuth() {
  const [authUser, setAuthUser] = useState<User | null>(null);
  const [authIsLoading, setAuthIsLoading] = useState(true);

  const clear = () => {
    setAuthUser(null);
    setAuthIsLoading(false);
  };

  const authStateChanged = async (user: any) => {
    setAuthIsLoading(true);

    if (!user) {
      // Anonymously sign-in user for tenant
      const userCredential: UserCredential = await signInAnonymously(auth);

      // Create User account
      await UserAccountService.createAnonymousUserAccount(
        String(userCredential.user.tenantId),
        userCredential.user
      ).catch((err) => {
        console.error(err);
        alert(err);
      });

      setAuthIsLoading(false);
      return;
    }

    setAuthUser(user);
    setAuthIsLoading(false);
  };

  const signOut = () => auth.signOut().then(() => clear());

  // Listen for Firebase Auth state changes
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, authStateChanged);
    return () => unsubscribe();
  }, []);

  return {
    authUser,
    authIsLoading,
    signOut,
  };
}

const AuthUserContext = createContext<{
  authUser: User | null;
  authIsLoading: boolean;
  signOut: () => Promise<void>;
}>({
  authUser: null,
  authIsLoading: true,
  signOut: async () => {},
});

export function AuthUserProvider({ children }: any) {
  const auth = useFirebaseAuth();
  return (
    <AuthUserContext.Provider value={auth}>{children}</AuthUserContext.Provider>
  );
}

export const useAuth = () =>
  useContext<{
    authUser: User | null;
    authIsLoading: boolean;
    signOut: () => Promise<void>;
  }>(AuthUserContext);
