/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/NBInnerWalls.glb -t -r public 
*/

import * as THREE from "three";
import React, { useEffect, useRef, useState } from "react";
import { useGLTF, useTexture, useVideoTexture } from "@react-three/drei";
import { GLTF } from "three-stdlib";
import { useInteractionContext } from "@/pages/viewer/viewer/InteractionContext";
import { useFrame } from "@react-three/fiber";
import { DELAY_MS } from "../shared/NBConstants";

type GLTFResult = GLTF & {
  nodes: {
    Cube029: THREE.Mesh;
    Cube029_1: THREE.Mesh;
  };
  materials: {};
};

type ContextType = Record<
  string,
  React.ForwardRefExoticComponent<JSX.IntrinsicElements["mesh"]>
>;

export function NBInnerWallsF(props: JSX.IntrinsicElements["group"]) {
  const { nodes, materials } = useGLTF("/glb/NBInnerWalls.glb") as GLTFResult;
  const { currentSceneId } = useInteractionContext();

  const hype = useVideoTexture("/videos/NBHype.mp4", {
    playsInline: true,
    muted: true,
  });
  hype.channel = 1;
  hype.flipY = false;
  hype.colorSpace = THREE.SRGBColorSpace;

  const nbinnerwallsd = useTexture("/textures/NBInnerWallsD.webp");
  nbinnerwallsd.flipY = false;
  nbinnerwallsd.colorSpace = THREE.SRGBColorSpace;
  nbinnerwallsd.channel = 3;

  const nbinnerwallsdarklm = useTexture("/textures/NBInnerWallsDarkLM.webp");
  nbinnerwallsdarklm.flipY = false;
  nbinnerwallsdarklm.colorSpace = THREE.SRGBColorSpace;
  nbinnerwallsdarklm.channel = 0;

  const nbinnerwallslightlm = useTexture("/textures/NBInnerWallsLightLM.webp");
  nbinnerwallslightlm.flipY = false;
  nbinnerwallslightlm.colorSpace = THREE.SRGBColorSpace;

  const nbhypemat = new THREE.MeshBasicMaterial({
    map: currentSceneId != "1" ? hype : nbinnerwallsdarklm,
  });

  const nbinnerwallsmat = new THREE.MeshBasicMaterial({
    map: nbinnerwallsd,
    lightMap: currentSceneId != "1" ? nbinnerwallslightlm : nbinnerwallsdarklm,
    lightMapIntensity: 7,
  });

  const nbinnerwallsdarkmat = new THREE.MeshBasicMaterial({
    map: nbinnerwallsd,
    lightMap: nbinnerwallsdarklm,
    lightMapIntensity: 7,
    transparent: true,
  });

  const nbinnerwallslightmat = new THREE.MeshBasicMaterial({
    map: nbinnerwallsd,
    lightMap: nbinnerwallslightlm,
    lightMapIntensity: 7,
    transparent: true,
  });

  const meshRef1 = useRef<THREE.Mesh>(null);
  const meshRef2 = useRef<THREE.Mesh>(null);

  const [fadeProgress, setFadeProgress] = useState(1);
  const [delayPassed, setDelayPassed] = useState(false);

  // manual delay for lightmap transition
  useEffect(() => {
    if (!delayPassed && currentSceneId !== "1") {
      const timer = setTimeout(() => {
        setDelayPassed(true);
        setFadeProgress(1);
      }, DELAY_MS);

      return () => clearTimeout(timer);
    }
  }, [currentSceneId, delayPassed]);

  useFrame((_, delta) => {
    if (meshRef1.current && meshRef2.current) {
      const material1 = meshRef1.current.material as THREE.MeshBasicMaterial;
      const material2 = meshRef2.current.material as THREE.MeshBasicMaterial;

      if (delayPassed) {
        const targetFade = 0;
        setFadeProgress((prev) =>
          THREE.MathUtils.lerp(prev, targetFade, delta * 0.7)
        );

        material2.opacity = 1 - fadeProgress;
        material1.opacity = fadeProgress;
      } else {
        material2.opacity = 0;
        material1.opacity = 1;
      }
    }
  });

  return (
    <group {...props} dispose={null}>
      <mesh
        ref={meshRef1}
        geometry={nodes.Cube029.geometry}
        material={nbinnerwallsdarkmat}
      />
      <mesh
        ref={meshRef2}
        geometry={nodes.Cube029.geometry}
        material={nbinnerwallslightmat}
      />
      <mesh geometry={nodes.Cube029.geometry} material={nbinnerwallsmat} />
      <mesh geometry={nodes.Cube029_1.geometry} material={nbhypemat} />
    </group>
  );
}
export const NBInnerWalls = () => {
  return <NBInnerWallsF></NBInnerWallsF>;
};
useGLTF.preload("/glb/NBInnerWalls.glb");
