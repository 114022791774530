import { createContext, useContext } from "react";

export const PageContext = createContext<{
  pageTitle: string;
  setPageTitle: React.Dispatch<React.SetStateAction<string>>;
}>({ pageTitle: "", setPageTitle: () => {} });

export function usePageContext() {
  const context = useContext(PageContext);
  return context;
}
