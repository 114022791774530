import { createContext, useContext } from "react";

export type InteractionContextType = {
  currentSceneId: string | null;
  interactableProducts: string[];
  currentlySelected?: string | null;
  setCurrentlySelected: (id: string | null) => void;
};

export const InteractionContext = createContext<InteractionContextType>({
  currentSceneId: null,
  interactableProducts: [],
  currentlySelected: null,
  setCurrentlySelected: () => {},
});

export const useInteractionContext = () => {
  const context = useContext(InteractionContext);
  return context;
};
