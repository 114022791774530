import { FC } from "react";

const DesktopPage: FC = function () {
  return (
    <div
      className="justify-center items-center flex flex-col w-screen h-screen bg-cover bg-center"
      style={{
        backgroundImage: 'url("/images/nb-desktop.png")',
      }}
    ></div>
  );
};

export default DesktopPage;
