/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/glb/NBRefreshment.glb -t -r public 
*/

import * as THREE from "three";
import React, { useEffect, useRef, useState } from "react";
import { useGLTF, useTexture } from "@react-three/drei";
import { GLTF } from "three-stdlib";
import { useInteractionContext } from "@/pages/viewer/viewer/InteractionContext";
import { useFrame } from "@react-three/fiber";
import { DELAY_MS } from "../shared/NBConstants";

type GLTFResult = GLTF & {
  nodes: {
    Cube025: THREE.Mesh;
    Cube025_1: THREE.Mesh;
  };
  materials: {};
};

type ContextType = Record<
  string,
  React.ForwardRefExoticComponent<JSX.IntrinsicElements["mesh"]>
>;

export function NBRefreshmentF(props: JSX.IntrinsicElements["group"]) {
  const { nodes, materials } = useGLTF("/glb/NBRefreshment.glb") as GLTFResult;
  const { currentSceneId } = useInteractionContext();

  const nbrefreshd = useTexture("/textures/NBRefreshmentD.webp");
  nbrefreshd.flipY = false;
  nbrefreshd.colorSpace = THREE.SRGBColorSpace;
  nbrefreshd.channel = 1;

  const nbrefreshlightlm = useTexture("/textures/NBRefreshmentLightLM.webp");
  nbrefreshlightlm.flipY = false;
  nbrefreshlightlm.colorSpace = THREE.SRGBColorSpace;
  nbrefreshlightlm.channel = 2;

  const nbrefreshdarklm = useTexture("/textures/NBRefreshmentDarkLM.webp");
  nbrefreshdarklm.flipY = false;
  nbrefreshdarklm.colorSpace = THREE.SRGBColorSpace;
  nbrefreshdarklm.channel = 2;

  const nbrefreshmat = new THREE.MeshBasicMaterial({
    map: nbrefreshd,
    lightMap: currentSceneId != "1" ? nbrefreshlightlm : nbrefreshdarklm,
    lightMapIntensity: 7,
    side: THREE.DoubleSide,
  });

  const nbrefreshdarkmat = new THREE.MeshBasicMaterial({
    map: nbrefreshd,
    lightMap: nbrefreshdarklm,
    lightMapIntensity: 7,
    side: THREE.DoubleSide,
    transparent: true,
  });

  const nbrefreshlightmat = new THREE.MeshBasicMaterial({
    map: nbrefreshd,
    lightMap: nbrefreshlightlm,
    lightMapIntensity: 7,
    side: THREE.DoubleSide,
    transparent: true,
  });

  const nbrefreshbottlesmat = new THREE.MeshStandardMaterial({
    map: nbrefreshd,
    lightMap: currentSceneId != "1" ? nbrefreshlightlm : nbrefreshdarklm,
    lightMapIntensity: 5,
    opacity: 0.85,
    transparent: true,
    roughness: 0.1,
  });

  const meshRef1 = useRef<THREE.Mesh>(null);
  const meshRef2 = useRef<THREE.Mesh>(null);
  const [fadeProgress, setFadeProgress] = useState(1);
  const [delayPassed, setDelayPassed] = useState(false);

  // manual delay for lightmap transition
  useEffect(() => {
    if (!delayPassed && currentSceneId !== "1") {
      const timer = setTimeout(() => {
        setDelayPassed(true);
        setFadeProgress(1);
      }, DELAY_MS);

      return () => clearTimeout(timer);
    }
  }, [currentSceneId, delayPassed]);

  useFrame((_, delta) => {
    if (meshRef1.current && meshRef2.current) {
      const material1 = meshRef1.current.material as THREE.MeshBasicMaterial;
      const material2 = meshRef2.current.material as THREE.MeshBasicMaterial;

      if (delayPassed) {
        const targetFade = 0;
        setFadeProgress((prev) =>
          THREE.MathUtils.lerp(prev, targetFade, delta * 0.7)
        );

        material2.opacity = 1 - fadeProgress;
        material1.opacity = fadeProgress;
      } else {
        material2.opacity = 0;
        material1.opacity = 1;
      }
    }
  });

  return (
    <group {...props} dispose={null}>
      <mesh
        ref={meshRef1}
        geometry={nodes.Cube025.geometry}
        material={nbrefreshdarkmat}
      />
      <mesh
        ref={meshRef2}
        geometry={nodes.Cube025.geometry}
        material={nbrefreshlightmat}
      />
      <mesh geometry={nodes.Cube025.geometry} material={nbrefreshmat} />
      <mesh
        geometry={nodes.Cube025_1.geometry}
        material={nbrefreshbottlesmat}
      />
    </group>
  );
}
export const NBRefreshment = () => {
  return <NBRefreshmentF></NBRefreshmentF>;
};
useGLTF.preload("/glb/NBRefreshment.glb");
